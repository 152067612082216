
export const backendRoot ='https://dashboarding-api-dot-dashboarding-359510.ue.r.appspot.com'
// export const backendRoot ="http://localhost:8000"

export const verifyTokenPath = 'account/token/verify/'

export const getTokenPath = 'account/token/'

export const signUpPath = 'account/signup/'

export const userInfoPath = 'account/user/'
